export const advantagesListHome = [
  {
    id: 'security',
    icon: 'security',
    title: 'Безопасность',
    text: 'Ваши аккаунты в безопасности. Мы работаем с соцсетями официально и не запрашиваем пароли.',
  },
  {
    id: 'charts',
    icon: 'charts',
    title: 'Понятные графики',
    text: 'Для каждого графика и каждой цифры есть описание — вы легко поймете, что значат метрики.',
  },
  {
    id: 'teams',
    icon: 'teams',
    title: 'Командная работа',
    text: 'Пригласите коллег, настройте доступ для них и улучшайте аккаунты в соцсетях вместе.',
  },
  {
    id: 'integrations',
    icon: 'integrations',
    title: 'Интеграции',
    text: 'Наш API позволяет подтягивать статистику по всем соцсетям из LD в ваши внутренние системы.',
  },
]
