import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { servicesDataHome } from '@data/servicesData'
import { advantagesListHome } from '@data/advantagesData'
import { reviewsDataHome } from '@data/reviewsData'
import { heroDataHome } from '@data/heroData'
import { brandsDataHome } from '@data/brandsData'

import useFormatMessage from '@utils/useFormatMessage'
import { getDefaultCurrency } from '@utils/Helper'

import Reviews from '@components/reviews/reviews'
import Header from '@components/layouts/header/header'
import HeadBasic from '@components/basic/headBasic'
import Footer from '@components/layouts/footer/footer'
import Hero from '@components/hero/hero'
import Services from '@components/services/services'
import Cta from '@components/cta/cta'
import Section from '@components/layouts/section/section'
import Advantages from '@components/advantages/advantages'
import PricingPlans from '@components/pricingPlans/pricingPlans'
import HeroNew from '@components/heroNew/heroNew'
import PricingSwitchers from '@components/pricingSwitchers/pricingSwitchers'
import { periods } from '@components/pricingSwitchers/period/periodData'
import Analysis from '@components/analysis/analysis'

const pageId = 'home'

export default function Home() {
  const t = useFormatMessage()
  const { locale } = useRouter()
  const [selectedPeriod, setSelectedPeriod] = useState(periods[1])
  const [selectedCurrency, setSelectedCurrency] = useState(
    getDefaultCurrency(locale)
  )

  useEffect(() => {
    setSelectedCurrency(getDefaultCurrency(locale))
  }, [locale])

  return (
    <>
      <HeadBasic
        title={t(`titles.${pageId}`)}
        description={t(`descriptions.${pageId}`)}
      />

      <Header pageId={pageId} />

      <Section>
        <Hero pageId={pageId} data={heroDataHome} brands={brandsDataHome} />
      </Section>

      {locale === 'ru' && (
        <Section className={`section--analysis`}>
          <Analysis pageId={pageId} />
        </Section>
      )}
      <Section className={`home__section--features`}>
        <Services pageId={pageId} data={servicesDataHome} />
      </Section>
      <Section className={`home__section--advantages section--dark`}>
        <Advantages pageId={pageId} data={advantagesListHome} />
      </Section>
      <Section>
        <HeroNew
          title={t(`hero.titles.pricing`)}
          text={t(`hero.descriptions.pricing`)}
          pageId={pageId}
        >
          <PricingSwitchers
            selectedPeriod={selectedPeriod}
            selectedCurrency={selectedCurrency}
            setSelectedPeriod={setSelectedPeriod}
            setSelectedCurrency={setSelectedCurrency}
          />
        </HeroNew>
      </Section>
      <Section>
        <PricingPlans
          selectedPeriod={selectedPeriod}
          selectedCurrency={selectedCurrency}
          pageId={pageId}
        />
      </Section>
      <Section>
        <Reviews data={reviewsDataHome} />
      </Section>
      <Section className={`section--cta`}>
        <Cta pageId={pageId} />
      </Section>
      <Footer pageId={pageId} />
    </>
  )
}
