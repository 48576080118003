import PropTypes from 'prop-types'
import cns from 'classnames'
import { useRouter } from 'next/router'

import useLanguageUrl from '@utils/useLanguageUrl'
import useFormatMessage from '@utils/useFormatMessage'
import $amplitude from '@utils/amplitude'
import { useABTest } from '@utils/useABTest'

import Advantage from '@components/advantages/advantage/advantage'

import styles from './advantages.module.scss'

export default function Advantages({ pageId, data = [] }) {
  const { locale } = useRouter()
  const t = useFormatMessage()
  const langUrl = useLanguageUrl()
  const { isABTestForPathname2 } = useABTest()

  const handleRegistrationButtonClick = () => {
    $amplitude('[Landing] Registration Button Click', {
      page_name: pageId,
      block_name: 'header',
    })
  }

  return (
    <div className={styles.advantages}>
      <div className={cns(styles.advantages__wrapper, `wrapper`)}>
        <div className={styles.advantages__content}>
          <div className={styles.advantages__header}>
            <h2
              className={cns(
                styles.advantages__title,
                `main-title`,
                `main-title--h2`
              )}
            >
              {t(`advantages.mainTitles.${pageId}`)}
            </h2>

            { locale !== 'br' && <a
              className={cns(
                styles.advantages__link,
                styles[`advantages__link--desktop`],
                `main-link`
              )}
              href={langUrl}
              onClick={handleRegistrationButtonClick}
              rel="nofollow"
            >
              {t(
                isABTestForPathname2
                  ? 'advantages.label.try'
                  : `advantages.linkTitles.${pageId}`
              )}
            </a>}
          </div>

          <ul className={styles.advantages__list}>
            {data.map((advantage, i) => (
              <Advantage data={advantage} key={i} />
            ))}
          </ul>

          <a
            className={cns(styles.advantages__link, `main-link`)}
            href={langUrl}
            onClick={handleRegistrationButtonClick}
            rel="nofollow"
          >
            {t(`advantages.linkTitle`)}
          </a>
        </div>
      </div>
    </div>
  )
}

Advantages.propTypes = {
  data: PropTypes.array,
  pageId: PropTypes.string,
}
