import cns from 'classnames'

import useFormatMessage from '@utils/useFormatMessage'

import Security from '@icons/security.svg'
import Charts from '@icons/charts.svg'
import Teams from '@icons/teams.svg'
import Integrations from '@icons/integrations.svg'

import styles from './advantage.module.scss'

const iconsMap = {
  security: Security,
  charts: Charts,
  teams: Teams,
  integrations: Integrations
}

const Icon = ({ name, className }) => {
  const Component = iconsMap[name]
  return <Component className={className} />
}

export default function Advantage({ data = [] }) {
  const t = useFormatMessage()
  const id = data?.id

  if (!id) return
  return (
    <li className={styles.advantage}>
      <div className={styles[`advantage__icon-wrapper`]}>
        <Icon name={data.icon} className={styles.advantage__icon} />
      </div>

      <h3 className={cns(styles.advantage__title, `main-title main-title--h3`)}>
        {t(`advantages.titles.${id}`)}
      </h3>

      <p className={cns(styles.advantage__text, `main-text`)}>
        {t(`advantages.texts.${id}`)}
      </p>
    </li>
  )
}
