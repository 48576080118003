export const servicesDataServices = [
  {
    title: 'Директ Инстаграм',
    text: 'Операторы, теги, заметки, статусы диалогов, статистика и другие функции помогут оптимизировать работу с Директом.',
    img: 'direct-instagram',
    alt: 'Директ Инстаграм',
    link: '/direct-instagram',
    linkTitle: 'Подробнее',
    params: `'land_services'`,
  },
  {
    title: 'Статистика аккаунтов',
    text: 'Ежечасный сбор аналитики по вашим аккаунтам и аккаунтам конкурентов для подробного анализа и поиска точек роста.',
    img: 'stats',
    alt: 'Динамика подписчиков',
    link: '/account-stats',
    linkTitle: 'Подробнее',
    params: `'land_services'`,
  },
  {
    title: 'Мониторинг',
    text: 'Мониторинг и обработка новых комментариев, сообщений и упоминаний из соцсетей в одном окне, анализ тональности и скорости ответа менеджеров.',
    img: 'monitoring',
    alt: 'Мониторинг комментариев',
    link: '/monitoring',
    linkTitle: 'Подробнее',
    params: 'land_main',
  },
  {
    title: 'Отчеты',
    text: 'Экспорт отчетов в форматах PDF, Google Презентации и Excel. Автоматические еженедельные и ежемесячные отчеты в почту.',
    img: 'reports',
    alt: 'Выгрузка отчетов',
    link: '/reports',
    linkTitle: 'Подробнее',
    params: `'land_services'`,
  },
  {
    title: 'Автопостинг',
    text: 'Запланируйте публикации во все ваши аккаунты на месяц вперед в одном месте. А мы опубликуем контент, соберем комментарии и статистику по ним.',
    img: 'autoposting',
    alt: 'Автопостинг',
    link: '/autoposting',
    linkTitle: 'Подробнее',
    params: `'land_services'`,
  },
  {
    title: 'Проверка блогеров',
    text: 'Поможет отобрать блогеров  без накруток и с хорошей активностью, чтобы не слить бюджет.',
    img: 'bloggers-check',
    alt: 'Проверка блогеров',
    link: '/bloggers-check',
    linkTitle: 'Подробнее',
    params: `'land_services'`,
  },
  {
    title: 'For empty slot',
  },
  {
    title: 'KPI',
    text: 'Установите KPI для каждого аккаунта и отслеживайте прогресс их выполнения в реальном времени. Больше не надо ничего вычислять в Excel.',
    img: 'kpi',
    alt: 'KPI',
    link: '/kpi',
    linkTitle: 'Подробнее',
    params: `'land_services'`,
  },
]

export const servicesDataHome = [
  {
    id: 'monitoring',
    img: 'monitoring',
    link: '/monitoring',
  },
  {
    id: 'accountStatistics',
    img: 'stats',
    link: '/account-stats',
  },
  {
    id: 'autoPosting',
    img: 'autoposting',
    link: '/autoposting',
  },
  {
    id: 'reports',
    img: 'reports',
    link: '/reports',
  },
  {
    id: 'kpi',
    img: 'kpi',
    link: '/kpi',
  },
  {
    id: 'bloggersCheck',
    img: 'bloggers-check',
    link: '/bloggers-check',
  },
]
