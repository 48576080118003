import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import cns from 'classnames'

import useFormatMessage from '@utils/useFormatMessage'
import $amplitude from '@utils/amplitude'
import { getLinkPrefix } from '@utils/getLinkPrefix'

import styles from './card.module.scss'

export default function Card({ pageId, data, imagesFolder = '' }) {
  const t = useFormatMessage()
  const id = data?.id
  const { locale } = useRouter()

  const handleLinkClick = () => {
    if (pageId === 'home') {
      $amplitude('[Landing] Features More Link Click', {
        product:
          id === 'accountStatistics'
            ? 'statistics'
            : id === 'monitoring'
            ? 'monitoring'
            : id === 'reports'
            ? 'reports'
            : id === 'autoPosting'
            ? 'autoposting'
            : id === 'bloggersCheck'
            ? 'blogger_checker'
            : 'kpi',
      })
    }
  }
  if (!id) return
  return (
    <div className={styles.card}>
      <div className={styles.card__box_img}>
        <picture>
          <source
            className={styles.card__img}
            srcSet={`/images/${imagesFolder && `${imagesFolder}/`}${locale}/${
              data.img
            }.webp`}
            type="image/webp"
          />
          <source
            className={styles.card__img}
            srcSet={`/images/${imagesFolder && `${imagesFolder}/`}${locale}/${
              data.img
            }.webp 1x, /images/${imagesFolder && `${imagesFolder}/`}${locale}/${
              data.img
            }@2x.webp 2x`}
            type="image/webp"
          />
          <img
            className={styles.card__img}
            src={`/images/${imagesFolder && `${imagesFolder}/`}${locale}/${
              data.img
            }.png`}
            srcSet={`/images/${imagesFolder && `${imagesFolder}/`}${locale}/${
              data.img
            }.png 1x, /images/${imagesFolder && `${imagesFolder}/`}${locale}/${
              data.img
            }@2x.png 2x`}
            alt={t(`services.titles.${pageId}.${id}`)}
            loading="lazy"
          />
        </picture>
      </div>

      <div className={styles.card__box_content}>
        <h3 className={styles.card__title}>
          {t(`services.titles.${pageId}.${id}`)}
        </h3>

        <p className={cns(styles.card__text, `main-text`)}>
          {t(`services.texts.${pageId}.${id}`)}
        </p>

        <Link
          className={cns(styles.card__link, `main-link`)}
          href={getLinkPrefix(locale, data.link)}
          onClick={handleLinkClick}
        >
          {t(`services.linkTitle`)}
        </Link>
      </div>
    </div>
  )
}

Card.propTypes = {
  pageId: PropTypes.string,
  data: PropTypes.object,
  imagesFolder: PropTypes.string,
}
