import cns from 'classnames'
import { useRouter } from 'next/router'

import useFormatMessage from '@utils/useFormatMessage'
import Card from '@components/card/card'

import styles from './services.module.scss'

const imagesFolder = 'servicesListHome'

export default function Services({ pageId, data }) {
  const { locale } = useRouter()
  const t = useFormatMessage()

  return (
    <div className={styles.services}>
      <div className={`wrapper`}>
        <div className={styles.services__header}>
          {locale !== 'br' && <p className={`section-label`}>{t(`services.labels.home`)}</p>}

          <h2
            className={cns(
              styles.services__mainTitle,
              `main-title main-title--h2`
            )}
          >
            {t(`services.titles.home`)}
          </h2>

          <p className={styles.services__description}>
            {t(`services.descriptions.home`)}
          </p>
        </div>

        <ul className={styles.services__list}>
          {data.map((service, i) => (
            <li className={styles.services__item} key={i}>
              <Card
                pageId={pageId}
                data={service}
                imagesFolder={imagesFolder}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
