import { useState } from 'react'
import cns from 'classnames'
import useFormatMessage from '@utils/useFormatMessage'

import $amplitude from '@utils/amplitude'

import ArrowRight from '@icons/arrow-right.svg'
import SearchThin from '@icons/search-thin.svg'

import styles from './analysis.module.scss'

const inputErrorMessage =
  'Пожалуйста, введите от 2 до 20 символов на английском языке без пробелов'

export default function Analysis() {
  const [input, setInput] = useState('')
  const [error, setError] = useState(null)
  const [focus, setFocus] = useState(false)

  const t = useFormatMessage()

  const handleInputChange = event => {
    setInput(event.target.value)
  }

  const handleFocusChange = () => {
    setFocus(true)
    handleOnFocusAmplitude()
  }

  const handleOnFocusAmplitude = () => {
    $amplitude('[Landing] Select Analyze Input Focus Click')
  }

  const handleClick = async e => {
    e.preventDefault()
    const isAllowed = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{2,20}$/.test(input)
    if (!isAllowed) {
      setInput('')
      setError(inputErrorMessage)
      $amplitude('[Landing] Search Account Analyze Button Click', {
        error_existence: true,
      })
      return
    }
    try {
      const res = await fetch(`/landing/searchAccounts?name=${input}`, {
        keepalive: true,
      })
      const data = await res.json()

      if (data.error) {
        setError(
          'У вас превышено число поисков. Для анализа аккаунта оплатите тариф.'
        )
        $amplitude('[Landing] Search Account Analyze Button Click', {
          error_existence: true,
        })
        return
      }
      if (data.data.length > 0) {
        setError(null)
        sessionStorage.setItem('accounts', JSON.stringify(data.data))
        location.href = `/ru/analysis-result?search=${input}`
        $amplitude('[Landing] Search Account Analyze Button Click', {
          error_existence: false,
        })
        return
      }
      setError('Ничего не найдено')
    } catch (error) {
      setError('Ничего не найдено')
      $amplitude('[Landing] Search Account Analyze Button Click', {
        error_existence: true,
      })
    }
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') handleClick(event)
  }

  return (
    <div className={styles.analysis}>
      <div
        className={cns(
          styles.analysis__wrapper,
          `analysis--wrapper`,
          `wrapper--extended`
        )}
      >
        <div className={styles.analysis__content}>
          <div className={styles.analysis__box}>
            <h2 className={styles.analysis__title}>Анализ аккаунта</h2>

            <p className={styles.analysis__text}>
              Узнать статистику аккаунта в разных соцсетях
            </p>
            <div className={styles.input__block}>
              <div
                className={cns(styles.input__container, {
                  [styles.input__container_error]: error,
                  [styles.input__container_clicked]: focus,
                })}
              >
                <div className={styles.search__block}>
                  <i className={styles.icon}>
                    <SearchThin />
                  </i>
                  <input
                    type="text"
                    placeholder="Введите название аккаунта"
                    className={styles.input}
                    value={input}
                    onChange={handleInputChange}
                    onFocus={handleFocusChange}
                    onBlur={() => setFocus(false)}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <button className={styles.input__button} onClick={handleClick}>
                  <ArrowRight />
                </button>
              </div>
              {error && <p className={styles.error}>{error}</p>}
            </div>
          </div>

          <img
            className={styles.image}
            src={`/images/analysis-brands.png`}
            alt="analysis-brands"
            loading="lazy"
          />
        </div>
        <p className={styles.socialsHint}>{t('analysis.availableSocials')}</p>
      </div>
    </div>
  )
}
